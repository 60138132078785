<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>我的女儿在美国念的高中，现在是大学在读。由于女儿长期在美国生活和学习，已经适应美国的环境，并且有毕业后留在美国工作的打算，出于生活和工作便利性的考虑，我决定为女儿办理移民。经过朋友的介绍，我了解到EB-5投资移民对投资人各方面的要求为宽松，办理手续也方便，因此我开始寻找合适的移民机构。</p>
        <p>市面上的EB-5项目很多，都把自己吹得天花乱坠，在我看花了眼的时候，我发现了跃迁投资移民，他们的项目和宣传，都说的是我关心的部分，即安全、快速，我通过朋友们了解了一下，得知跃迁在移民行业内一直较有名，并且享有较高的成功率的记录，再三考虑下，我前往跃迁进行咨询，跃迁员工的专业和热情打动了我，挑选了项目后，我决定签约，跃迁的文案在了解了我和我女儿的情况后，也当场给我列了文件清单，非常有效率。</p>
        <p>整个文件准备过程中，我也遇到了一些困难，有些年代久远的文件，因为遗失或丢失，已经无法提供。这种情况下，跃迁的文案非常耐心地给我提供了备选和解决的方案，并且积极地为我联系各种当地的办事机构，极快地解决了这些文件上的困难，为申请文件的完整性提供了保障。有一些文件需要我的女儿提供，由于时差的原因，我女儿只能在深夜通过微信进行沟通，跃迁的文案也没有丝毫的怠慢，在好几个晚上和我女儿交流到半夜，也让我觉得非常感动。</p>
        <p>现在，我的移民申请已经在移民局公布的平均审核周期内获得了批准，在等待期间我和我女儿对于申请的任何问题，跃迁的员工都悉数给了我解答，并且还会定期告知我们项目的新进展，让我们觉得非常的放心。因此，我觉得选择跃迁是正确的选择。</p>
        <p>
          <img loading="lazy" class="aligncenter" src="@/assets/Cases/5/1.jpg">
        </p>
      </div>
      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases7",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>